import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-hero-banner.css";
import ExternalSystemMessageTray from "./rds-external-system-message";
import Rdsbtn from "./rds-btn";

const RdsHeroBanner = ({
  bannerImage,
  bannerLogo,
  title,
  subTitle,
  description,
  externalFranchiseMessage,
  cta,
  franchiseData,
}) => {
  const linkButtonProps = {
    text: cta?.text,
    buttonClass: "rds rds-btn primary",
    iconClass: "fa-icon leading fa-regular fa-magnifying-glass",
    iconType: "leading",
    disabled: false,
  };

  const handleClick = () => {
    if (cta.type === "Link" && cta.openInNewTab) {
      window.open(cta.value, "_blank");
    }
    if (cta.type === "Link" && !cta.openInNewTab) {
      window.open(cta.value, "_self");
    }
  };
  const headerFranchisePhone = franchiseData?.franchiseDetails?.headerFranchisePhone;

  const renderRdsBtn = !headerFranchisePhone ? null : (
    <Rdsbtn
      tel={headerFranchisePhone}
      text={cta?.text}
      buttonClass="rds rds-btn primary"
      iconClass = {"fa-icon leading fa-regular fa-magnifying-glass"}
      iconType = {"leading"}
      disabled = {false}
      franchiseDetails={franchiseData?.franchiseDetails}
    />
  );

  return (
    <>
      {externalFranchiseMessage && (
        <ExternalSystemMessageTray
          externalFranchiseMessage={externalFranchiseMessage}
        />
      )}
      <div
        className="hero-banner-tray"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="hero-banner-background">
          <div className="container hero-banner-container d-flex align-items-center">
            <div className="column col-12 col-md-6 pe-0 pe-md-5 content">
              {(!cta || (cta && !headerFranchisePhone)) && bannerLogo && (
                <img src={bannerLogo} alt="AceLogo" className="banner-logo" />
              )}
              <h1>{title}</h1>
              {subTitle && <div className="subtitle">{subTitle}</div>}
              {description && <p>{description}</p>}

              {cta && cta.type === "Link" && (
                <Rdsbtn {...linkButtonProps} onClick={handleClick} />
              )}

              {cta && cta.type === "Phone_Number" && renderRdsBtn}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RdsHeroBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerLogo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};

export default RdsHeroBanner;
