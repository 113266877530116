import React, { useState, useEffect } from "react";
import DynamicTrays from "../../Components/DynamicTrays";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFranchiseBlogs } from "../../redux-toolkit/slice/franchiseBlogs/franchiseBlogsSlice";
import { useSelector, useDispatch } from "react-redux";
import ErrorPage from "../../Components/ErrorPage";
import { addSelectedFilter } from "../../redux-toolkit/slice/blogsFilter/blogsFilterSlice";
import useMetaDetails from "../../hooks/useMetaDetails";

const FranchiseBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { franchiseName } = useParams();
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseBlogs
  );

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}`;
    dispatch(fetchFranchiseBlogs(payLoad));
  }, [franchiseName]);

  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  const handleSeeAllBlogPosts = (redirectURL) => {
    navigate(redirectURL);
  };

  const handleRedirectFromBLP = (data) => {
    const { selectedCategory, redirectURL } = data;
    dispatch(addSelectedFilter(selectedCategory));
    navigate(redirectURL);
  };

  const handleOnClickRedirectAction = (redirectUrl) => {
    navigate(redirectUrl);
  };

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            handleSeeAllBlogPosts={handleSeeAllBlogPosts}
            handleRedirectFromBLP={handleRedirectFromBLP}
            onClickRedirectAction={handleOnClickRedirectAction}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default FranchiseBlogs;
