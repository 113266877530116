import { configureStore } from "@reduxjs/toolkit";

import lineOfBusinessReducer from "../slice/lineOfBusiness/lineOfBusinessSlice";
import lineOfBusinessServiceReducer from "../slice/lineOfBusinessService/lineOfBusinessServiceSlice";
import LineOfBusinessServiceDetailsReducer from "../slice/lineOfBusinessServiceDetails/lineOfBusinessServiceDetailsSlice";
import LineOfBusinessServiceLocationsReducer from "../slice/lineOfBusinessServiceLocations/lineOfBusinessServiceLocationsSlice";
import PrivacyPolicyReducer from "../slice/PrivacyPolicy/PrivacyPolicySlice";
import TermsAndConditionsReducer from "../slice/TermsAndConditions/TermsAndConditionsSlice";
import OffersTermsAndConditionsReducer from "../slice/OffersTermsAndConditions/OffersTermsAndConditionsSlice";
import franchiseHomeReducer from "../slice/franchiseHome/franchiseHomeSlice";
import franchiseSEOCityReducer from "../slice/franchiseSEOCity/franchiseSEOCitySlice";
import franchiseOurCompanyReducer from "../slice/franchiseOurCompany/franchiseOurCompanySlice";
import franchiseCareersReducer from "../slice/franchiseCareers/franchiseCareersSlice";
import franchiseBlogsReducer from "../slice/franchiseBlogs/franchiseBlogsSlice";
import blogPostReducer from "../slice/blogPost/blogPostSlice";
import franchiseMaintenanceReducer from "../slice/franchiseMaintenance/franchiseMaintenanceSlice";
import franchiseCommercialReducer from "../slice/franchiseCommercial/franchiseCommercialSlice";
import franchiseHeaderReducer from "../slice/franchiseHeader/franchiseHeaderSlice";
import franchiseContactReducer from "../slice/franchiseContact/franchiseContactSlice";
import franchiseBookingServiceReducer from "../slice/franchiseBookingService/franchiseBookingServiceSlice";
import franchiseFAQReducer from "../slice/franchiseFAQ/franchiseFAQSlice";
import franchiseCampaignReducer from "../slice/franchiseCampaign/franchiseCampaignSlice";
import franchisePromotionsReducer from "../slice/franchisePromotions/franchisePromotionsSlice";
import blogFilterReducer from "../slice/blogsFilter/blogsFilterSlice";
import metaReducer from "../slice/metaSlice/metaSlice";
import sitemapReducer from "../slice/sitemap/sitemapSlice";
import corporateBlogFiltersReducer from "../slice/corporateBlogFilterAPI/corporateBlogFilterSlice";
import corporateBlogListReducer from "../slice/corporateBlogFilterAPI/corporateBlogListSlice";

export const store = configureStore({
  reducer: {
    lineOfBusiness: lineOfBusinessReducer,
    lineOfBusinessService: lineOfBusinessServiceReducer,
    lineOfBusinessServiceDetails: LineOfBusinessServiceDetailsReducer,
    lineOfBusinessServiceLocations: LineOfBusinessServiceLocationsReducer,
    PrivacyPolicy: PrivacyPolicyReducer,
    TermsAndConditions: TermsAndConditionsReducer,
    OffersTermsAndConditions: OffersTermsAndConditionsReducer,
    franchiseHome: franchiseHomeReducer,
    franchiseSEOCity: franchiseSEOCityReducer,
    franchiseOurCompany: franchiseOurCompanyReducer,
    franchiseCareers: franchiseCareersReducer,
    franchiseMaintenance: franchiseMaintenanceReducer,
    franchiseCommercial: franchiseCommercialReducer,
    franchiseHeader: franchiseHeaderReducer,
    franchiseContact: franchiseContactReducer,
    franchiseBookingService: franchiseBookingServiceReducer,
    franchiseFAQ: franchiseFAQReducer,
    franchiseCampaign: franchiseCampaignReducer,
    franchisePromotions: franchisePromotionsReducer,
    blogFilter: blogFilterReducer,
    meta: metaReducer,
    sitemap: sitemapReducer,
    corporateBlogFilters: corporateBlogFiltersReducer,
    corporateBlogList: corporateBlogListReducer,
    franchiseBlogs: franchiseBlogsReducer,
    blogPost: blogPostReducer,
  },
});
