import React, { useState } from "react";
import "../stylesheets/rds-services-tray.css";
import { Markup } from "interweave";

const RdsServicesTray = ({
  tabName,
  summary,
  services,
  footer,
  anchorTagValue,
  anchorTagClass,
  handleOnClickRedirectAction
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`rds-services-background ${anchorTagClass}`}
      id={anchorTagValue}
    >
      <h2 className="rds-services-heading rds-section-heading">
        {tabName}
        <i
          className={
            "fa-icon " +
            (open ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down") +
            " rds-service-icon"
          }
          onClick={() => setOpen(!open)}
        ></i>
      </h2>
      {!open && (
        <div className="container rds-services">
          <div className="row rds-services-row">
            {services?.map((details, index) => {
              return (
                <div
                  key={index}
                  className="col-md-2 col-sm-4 col-xs-12 rds-services-cols"
                >
                  <div className="rds-services-main-head-anchor">
                    <a
                      className="rds-services-head-anchor"
                      onClick={(event) => handleOnClickRedirectAction(event, details?.redirectUrl)}
                      href={details?.redirectUrl}
                    >
                      {details?.name}
                    </a>
                  </div>
                  {details.subServices.map((sub, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className="rds-services-main-sub-anchor"
                      >
                        <a
                          className="rds-services-sub-anchor"
                          onClick={(event) => handleOnClickRedirectAction(event, sub?.redirectUrl)}
                          href={sub?.redirectUrl}
                        >
                          {sub?.name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="rds-services-footer">
            <Markup content={summary} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RdsServicesTray;
