import React from "react";
import { Link } from "react-router-dom";
import { getTextStyle, getTrayStyle } from "../utility";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-service-areas-tray.module.css";
import "../stylesheets/rds-seo-city-tray.css";

const CityList = ({ data, anchorTagValue, textStyle }) => {
  const columns = window.innerWidth < 768 ? 2 : 3;
  const rowsPerColumn = Math.ceil(data.length / columns);
  const cityColumns = Array.from({ length: columns }, () => []);

  data.forEach((city, index) => {
    cityColumns[Math.floor(index / rowsPerColumn)].push(city);
  });

  return (
    <div className="container" id={anchorTagValue}>
      <div className="row">
        {cityColumns.map((column, colIndex) => (
          <div key={colIndex} className="col-6 col-md-4 mb-3">
            {column.map((city) => (
              <div key={city.cityName} className="city-name-wrapper">
                {city.redirectUrl ? (
                  <Link
                    to={city.redirectUrl}
                    target={"_self"}
                    className={styles.link}
                    style={textStyle}
                  >
                    {city.cityName}
                  </Link>
                ) : (
                  <span className="city-name" style={textStyle}>
                    {city.cityName}
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const RdsSeoCityTray = (props) => {
  const {
    heading,
    imageAlignment,
    imageUrl,
    gmbMapUrl,
    sectionHeading,
    seoCityList = [],
    subHeading,
    trayDimension,
    backgroundColor = "#fff",
    renderAction,
    anchorTagValue = "",
    imageAltText,
  } = props;

  const trayStyle = getTrayStyle(backgroundColor);
  const textStyle = getTextStyle(backgroundColor);

  const rowReverse = imageAlignment === "Right" ? false : true;

  const imgRatio =
    trayDimension === "50-50"
      ? styles.fb50
      : trayDimension === "70-30"
      ? styles.fb30
      : styles.fb70;

  const copyRatio =
    trayDimension === "50-50"
      ? styles.fb50
      : trayDimension === "70-30"
      ? styles.fb70
      : styles.fb30;

  return (
    <div
      className={`${styles.rdsServiceAreasTray} pt-5 pb-3`}
      style={{ ...trayStyle }}
      id={anchorTagValue}
    >
      <div className="container">
        <div className="head-wrapper">
          {sectionHeading && (
            <h2 className="rds-section-heading" style={textStyle}>
              {sectionHeading}
            </h2>
          )}
          {heading && (
            <h3 className="rds-heading" style={textStyle}>
              {heading}
            </h3>
          )}
          {subHeading && (
            <h4 className="rds-subHeading" style={textStyle}>
              {subHeading}
            </h4>
          )}
        </div>
        <div
          className={`${styles.rdsServiceAreasTrayContent} ${
            rowReverse ? styles.rowReverse : styles.rowDir
          }`}
        >
          <div className={`${styles.copyContainer} ${copyRatio}`}>
            <CityList data={seoCityList} textStyle={textStyle} />
            <div className="mb-4" style={textStyle}>
              {renderAction}
            </div>
          </div>

          {gmbMapUrl ? (
            <div className={styles.mapContainer}>
              <iframe
                className={styles.map}
                src={gmbMapUrl}
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          ) : (
            <div className={`${styles.imgContainer} ${imgRatio}`}>
              <img
                src={imageUrl}
                alt={imageAltText ? imageAltText : sectionHeading}
                title={imageAltText ? imageAltText : sectionHeading}
                className={styles.image}
                width="100"
                height="100"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RdsSeoCityTray;
