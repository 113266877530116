export const ROOT = {
  lineOfBusiness: "/api/acehomeservices/line-of-business",
  lineOfBusinessService: "/api/acehomeservices/line-of-business-service",
  lineOfBusinessServiceDetails:
    "/api/acehomeservices/line-of-business-service-details",
  lineOfBusinessServiceLocations:
    "/api/acehomeservices/line-of-business-service-locations",
  PrivacyPolicy: "/api/acehomeservices/privacy-policy",
  TermsAndConditions: "/api/acehomeservices/terms-and-conditions",
  OffersTermsAndConditions: "/api/acehomeservices/offers-terms-and-conditions",
  franchiseHome: "/api/acehomeservices/franchise-home",
  franchiseSEOCity: "/api/acehomeservices/franchise-seo-city",
  franchiseOurCompany: "/api/acehomeservices/franchise-our-company",
  franchiseCareers: "/api/acehomeservices/franchise-careers",
  franchiseMaintenance: "/api/acehomeservices/franchise-maintenance",
  franchiseCommercial: "/api/acehomeservices/franchise-commercial",
  franchiseHeader: "/api/acehomeservices/franchise-header-data",
  franchiseContact: "/api/acehomeservices/franchise-contact-us",
  franchiseBookingService: "/api/acehomeservices/franchise-book-service",
  franchiseFAQ: "/api/acehomeservices/faqs",
  franchiseCampaign: "/api/acehomeservices/franchise-campaign",
  franchisePromotions: "/api/acehomeservices/franchise-promotions",
  sitemap: "/api/acehomeservices/sitemap",
  franchiseSendEmail: "/api/acehomeservices/send-email",
  corporateBlogFilters: "/api/acehomeservices/corporate-blogs-filters",
  corporateBlogList:"/api/acehomeservices/corporate-blogs",
  franchiseBlogs: "/api/acehomeservices/franchise-blogs",
  blogPost: "/api/acehomeservices/blog-post",
};

