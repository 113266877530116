import React from "react";
import "../stylesheets/rds-promotions-tray.css";
import { getTextStyle, getTrayStyle } from "../utility";
import { Markup } from "interweave";
import PropTypes from "prop-types";
import Rdsbtn from "../components/rds-btn";
import logo from "../assets/AceHomeServices.png";
import "../stylesheets/rds-common.css";
import { v4 as uuidv4 } from "uuid";
import Tip from "./SvgIconComponents/Tooltip/Tip";
import RenderServiceIcon from "./SvgIconComponents/ServiceIcons/RenderServiceIcon";

export default function RdsPromotionsTray({
  offers,
  backgroundColor,
  sectionHeading,
  heading,
  subHeading,
}) {
  const callButtonProps = {
    text: "CALL FOR DETAILS",
    buttonClass: "rds rds-btn secondary custom-btn",
    iconClass: "fa-icon leading fa-regular fa-phone",
    iconType: "leading",
    disabled: false,
  };
  const printButtonProps = {
    text: "PRINT",
    buttonClass: "rds rds-btn primary",
    iconClass: "fa-icon leading fa-regular fa-print",
    iconType: "leading",
    disabled: false,
  };
  const iconProperties = {
    fillColor: "#D40029",
    strokeColor: "#D40029",
  };

  const print = () => {
    const rows = [];
    if (offers && offers.length > 0) {
      for (let i = 0; i < offers.length; i += 3) {
        const rowOffers = offers.slice(i, i + 3);
        const cols = rowOffers.map((offer, index) => (
          <div className="print-offer-class">
            <div className="print-offer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  alt="Ace Home services"
                  width="300"
                  height="150"
                />
              </div>
              {offer.heading && (
                <div
                  className="heading"
                  style={{
                    fontSize: "34px",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "25px",
                  }}
                >
                  {offer.heading}
                </div>
              )}
              {offer.summary && (
                <div
                  className="summary"
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    fontSize: "20px",
                  }}
                >
                  <Markup content={offer.summary} />
                </div>
              )}
              {offer.termsAndCondition && (
                <div
                  className="termsAndCondition"
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    fontSize: "20px",
                  }}
                >
                  <Markup content={offer.termsAndCondition} />
                </div>
              )}
            </div>
          </div>
        ));
        rows.push(
          <div className="row justify-content-center rds-rows" key={i}>
            {cols}
          </div>
        );
      }
    }
    return rows;
  };

  const renderOffers = () => {
    const rows = [];
    if (offers && offers.length > 0) {
      for (let i = 0; i < offers.length; i += 3) {
        const rowOffers = offers
          ?.slice(i, i + 3)
          ?.map((offer) => ({ ...offer, id: uuidv4() }));
        const cols = rowOffers.map((offer, index) => (
          <div
            className="col-md-4 mb-5 d-flex flex-column align-items-center rds-offer"
            key={index}
          >
            {offer.sectionHeading && (
              <div className="rds-offer-serviceLine rds-font-button mb-2">
                {RenderServiceIcon(
                  offer.serviceLine?.split(" ")[0],
                  iconProperties
                )}
                <Markup content={offer.serviceLine} />
              </div>
            )}
            {offer?.expirationDate && (
              <div className="rds-font-label mb-2" style={textStyle}>
                {`OFFER EXPIRES ${offer?.expirationDate}`}
              </div>
            )}
            {offer.heading && (
              <div className="rds-offer-heading mb-2">{offer.heading} </div>
            )}
            {offer.summary && (
              <div className="rds-offer-summary">
                <Markup content={offer.summary} />
              </div>
            )}
            {offer.termsAndCondition && (
              <div className="rds-offer-termsAndCondition">
                {offer.termsAndCondition?.length > 125 && (
                  <input type="checkbox" id={offer.id} className="checkbox" />
                )}
                <span className="truncated-description">
                  {offer.termsAndCondition}
                  {offer.termsAndCondition?.length > 125 && (
                    <label
                      htmlFor={offer.id}
                      className="ellipsis rds-font-body-2"
                    >
                      ...
                      <span className="see-more rds-font-body-2">See more</span>
                    </label>
                  )}
                </span>
                <span className="full-description">
                  {offer.termsAndCondition}{" "}
                </span>
                <div className="icon">
                  <Tip />
                </div>
                <div className="pop-up">
                  <p className="pop-up-text rds-font-subtitle-2">
                    {offer.termsAndCondition}
                  </p>
                </div>
              </div>
            )}
            <div className="rds-offer-button-container d-flex">
              <Rdsbtn
                className="rds-button-call"
                {...callButtonProps}
                companyDetails={offer?.heading}
              />
              <Rdsbtn
                className="rds-button-print"
                {...printButtonProps}
                index={index}
                companyDetails={offer?.heading}
              />
            </div>
          </div>
        ));
        rows.push(
          <div className="row justify-content-center rds-rows" key={i}>
            {cols}
          </div>
        );
      }
    }
    return rows;
  };

  const trayStyle = getTrayStyle(backgroundColor);
  const textStyle = getTextStyle(backgroundColor);

  return (
    <div className="rds-wrapper" style={trayStyle}>
      <div className="container rds-offers-container">
        {sectionHeading && (
          <h2 className="rds-section-heading" style={textStyle}>
            {sectionHeading}
          </h2>
        )}
        {heading && (
          <h3 className="rds-heading" style={textStyle}>
            {heading}
          </h3>
        )}
        {subHeading && (
          <h4 className="rds-subHeading" style={textStyle}>
            {subHeading}
          </h4>
        )}
        <div className="mb-4"></div>
        {renderOffers()}
        {print()}
      </div>
    </div>
  );
}

RdsPromotionsTray.propTypes = {
  sectionHeading: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      heading: PropTypes.string,
      summary: PropTypes.string,
      termsAndCondition: PropTypes.string,
    })
  ).isRequired,
};
