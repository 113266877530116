import React from "react";
import style from "../stylesheets/rds-recent-blogs-tray.module.css";
import "../stylesheets/rds-review-carousel.css";
import RdsCard from "./rds-card";

const RdsFranchiseBlogPostTray = ({
    heading,
    sectionHeading,
    cta,
    blogPosts,
    handleSeeAllBlogPosts,
    handleRedirectFromBLP,
    onClickRedirectAction
}) => {

    const handleSeeAllBlogPostsRedirect = (url, tab) => {
        handleSeeAllBlogPosts?.(url, tab);
    };

    const handleRedirect = (selectedCategory, redirectURL) => {
        handleRedirectFromBLP?.({ selectedCategory, redirectURL });
    };

    return (
        <div className="container container-fluid border-top">
            <div className="row justify-content-center pt-4">
                <h2 className="rds-review-carousel-heading rds-section-heading">{sectionHeading}</h2>
                <h3 className="rds-review-carousel-subHeading rds-heading mb-4">{heading}</h3>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-2 mb-4">
                {blogPosts?.slice(0, 3)?.map((blogData, index) => {
                    return (
                        <div className={`${style.cardWrapper}`} key={index}>
                            <RdsCard
                                variant={"vertical"}
                                title={blogData?.heading}
                                footer={blogData?.datePublished}
                                image={blogData?.featuredImage}
                                subtitle={blogData?.subHeading}
                                categories={blogData?.categories.slice(0, 1)}
                                handleRedirect={handleRedirect}
                                redirectUrl={blogData?.redirectUrl}
                                onClickRedirectAction={onClickRedirectAction}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
                {blogPosts?.slice(3, 5)?.map((blogData, index) => {
                    return (
                        <div className={`${style.cardWrapper}`} key={index}>
                            <RdsCard
                                variant={"vertical"}
                                title={blogData?.heading}
                                footer={blogData?.datePublished}
                                image={blogData?.featuredImage}
                                subtitle={blogData?.subHeading}
                                categories={blogData?.categories.slice(0, 1)}
                                handleRedirect={handleRedirect}
                                redirectUrl={blogData?.redirectUrl}
                                onClickRedirectAction={onClickRedirectAction}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="d-flex justify-content-center py-4">
                <div
                    onClick={() => handleSeeAllBlogPostsRedirect(cta?.redirectUrl, cta?.openInNewTab)}
                    className={`rds-review-see-all ${style.buttonStyle}`}
                >
                    {cta?.text + " "}
                    <i className="fa-icon trailing fa-regular fa-arrow-right ml-2"></i>
                </div>
            </div>
        </div>
    );
};

export default RdsFranchiseBlogPostTray;
