import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFranchiseHeader } from "../../redux-toolkit/slice/franchiseHeader/franchiseHeaderSlice";
import { fetchFranchiseHome } from "../../redux-toolkit/slice/franchiseHome/franchiseHomeSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import { addSelectedFilter, clearAllFilter } from "../../redux-toolkit/slice/blogsFilter/blogsFilterSlice";

const FranchiseHome = () => {
  const { franchiseName } = useParams();
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseHome
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  const handleOnClickRedirectAction = (event, redirectUrl) => {
    event.preventDefault();
    navigate(`${redirectUrl}`);
  }

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}`;
    dispatch(fetchFranchiseHome(payLoad));
    dispatch(fetchFranchiseHeader(payLoad));
  }, [franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  const handleRedirectFromBLP = (data) => {
    const { selectedCategory, redirectURL } = data;
    dispatch(clearAllFilter())
    dispatch(addSelectedFilter(selectedCategory));
    navigate(redirectURL);
  }

  const onClickRedirectAction = (redirectUrl) => {
    navigate(redirectUrl);
  }

  const handleSeeAllBlogPosts = (redirectURL) => {
    navigate(redirectURL);
  }

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            externalFranchiseMessage={externalFranchiseMessage}
            handleSeeAllBlogPosts={handleSeeAllBlogPosts}
            handleOnClickRedirectAction={handleOnClickRedirectAction}
            handleRedirectFromBLP={handleRedirectFromBLP}
            onClickRedirectAction={onClickRedirectAction}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default FranchiseHome;
